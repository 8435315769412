import React from 'react';
import PropTypes from 'prop-types';
import styles from './no_data.module.scss';
import classNames from 'classnames';

const NoData = (props) => {
  const {
    small,
    large,
    className: classNameInGlobalScope,
    textDescription,
    additionalText,
  } = props;

  return (
    <div
      data-testid='no-data'
      className={classNames(
        styles.noData,
        classNameInGlobalScope,
        small ? styles.sizeSmall : large ? styles.sizeLarge : styles.sizeMedium
      )}
    >
      <span className={classNames('fal icon-empty-set', styles.icon)}></span>
      <p id='textDescription' data-testid='text-description' className={styles.textDescription}>
        {textDescription}
      </p>
      {additionalText && (
        <p data-testid='additional-text' className={styles.additionalText}>
          {additionalText}
        </p>
      )}
    </div>
  );
};

NoData.defaultProps = {
  className: '',
  textDescription: 'No results',
  additionalText: null,
};

NoData.propTypes = {
  className: PropTypes.string,
  textDescription: PropTypes.string,
  small: PropTypes.bool,
  large: PropTypes.bool,
  additionalText: PropTypes.string || null,
};

export default NoData;
